<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-07-27 09:33:10
 * @LastEditors: ------
 * @LastEditTime: 2021-08-17 14:09:06
-->
<template>
  <div class="bg">
    <div class="top">
      <img src="../image/15.png" alt="" class="top1" />
      <img src="../image/shouye(1).png" alt="" class="top2" />
      <span @click="btnClick">首页</span>
    </div>
    <main>
      <div class="container_box cursor" @click="go('nurseList')">
        <div class="picture">
          <img src="../image/choose3.png" />
          <div>护理员档案</div>
        </div>
      </div>
      <div class="container_box cursor" @click="go('serveManage')">
        <div class="picture ">
          <img src="../image/choose2.png" />
          <div>服务合同管理</div>
        </div>
      </div>
      <div class="container_box cursor" @click="go('customerList')">
        <div class="picture" @click="go('customerList')">
          <img src="../image/choose1.png" />
          <div>客户管理</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { getCustomerList } from "../api/api";
import router from "../router/index";

export default {
  name: "home",
  data() {
    return {};
  },

  created() {
    getCustomerList({ pageSize: 1 }).then((res) => {
      if (res.status == 200) {
        // console.log(res.data.result.total);
      }
    });
  },

  methods: {
    go(key) {
      switch (key) {
        case "nurseList":
          router.push("/nurseList");
          break;
        case "serveManage":
          router.push("/serveManage");
          break;
        case "customerList":
          router.push("/customerList");
          break;
        default:
          break;
      }
    },
  },
  beforeRouteEnter(to, from) {
    if (from.path == "/") {
      if (location.href.indexOf("home") == -1) {
        location.href = location.href + "home";
        location.reload();
      }
    }
  },
};
</script>

<style scoped>
.bg {
  background: #ffffff;
  margin-top: 2px;
}
.top {
  display: flex;
  padding-left: 366px;
  align-items: center;
  margin: 26px 0;
}
.top span {
  font-size: 22px;
}
.top1 {
  margin-right: 10px;
  height: 30px;
  width: 4px;
}
.top2 {
  width: 21px;
  height: 22px;
  margin-right: 14px;
}
main {
  height: 702px;
  width: 1442px;
  background: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.27);
  border-radius: 12px;
  margin: 0 auto;
  margin-bottom: 82px;
  display: flex;
  justify-content: center;
  font-weight: 400;
  color: #333333;
  font-size: 24px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
}
.container_box {
  width: 388px;
  height: 270px;
  background: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.27);
  border-radius: 12px;
  margin-left: 18px;
  margin-top: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.picture img {
  width: 100px;
  height: 100px;
}
.picture div {
  margin-top: 30px;
}
</style>
